import React from 'react'
import { Link } from 'gatsby'

import { ArchivesQuery, YearlyArchivesQuery } from '../../../graphql-types'

type Props = {
  year: string
  posts: NonNullable<ArchivesQuery['allMarkdownRemark']['nodes'] | YearlyArchivesQuery['allMarkdownRemark']['nodes']>
}

const PostArticle: React.FC<Props> = ({ year, posts }) => {
  return (
    <section className="my-6">
      <h2>
        <Link to={`/archives/${year}`} className="text-black hover:text-black">
          {year}
          <small className="text-gray-600 ml-2">
            ({posts.length})
          </small>
        </Link>
      </h2>
      <ul>
        {posts.map((post, i) => (
          <li key={i}>
            <Link to={post.fields!.slug || ''}>
              {post.frontmatter!.title}
            </Link>
            <small className="text-gray-600 ml-2">
              {post.frontmatter!.date}
            </small>
          </li>
        ))}
      </ul>
    </section>
  )
}

export default PostArticle
