import React from 'react'
import { PageProps, graphql } from 'gatsby'
import uniq from 'lodash/uniq'

import { ArchivesQuery } from '../../graphql-types'
import Layout from '../components/layout'
import SEO from '../components/seo'
import PostArchive from '../components/organisms/PostArchive'

const ArchivesPage: React.FC<PageProps<ArchivesQuery>> = ({ data }) => {
  const allPosts = data.allMarkdownRemark.nodes
  const years = uniq(allPosts.map(post => post.frontmatter!.year)).sort().reverse()

  return (
    <Layout>
      <SEO title="archives" />
      {years.map((year) => {
        const yearlyPosts = allPosts.filter(post => post.frontmatter!.year === year)
        return (
          <PostArchive
            key={year}
            year={year}
            posts={yearlyPosts}
          />
        )
      })}
    </Layout>
  )
}

export default ArchivesPage

export const pageQuery = graphql`
  query Archives {
    allMarkdownRemark (
      filter: { fields: { slug: { regex: "/^\/post\//" } } }
      sort: {fields: frontmatter___date, order: DESC}
    ) {
      nodes {
        fields {
          slug
        }
        frontmatter {
          year: date(formatString: "YYYY")
          date(formatString: "YYYY-MM-DD")
          title
        }
      }
    }
  }
`
